import React from 'react';
import Header from '../../shared/Header'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch (codeLang) {
        case 'python':
            return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
            </Code>
        case 'http':
            return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

{
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        authority: 'SAT',
        stickerType: 'line',
        dataType: 'rfc',
        data: 'ARCX9012226P8',
        imageType: 'hash',
        email: 'jhon@gmail.com',
        page: 0,
        rect: {
            lx: 388,
            ly: 400,
            tx: 496,
            ty: 480
            } 
    }],
    workflow: {
        subject: 'Asunto del correo de invitación',
        message: 'Mensaje personalizado en el correo de invitación',
        expirationDate: '12/11/2021',
        remindEvery: '1d',
        language: 'es',
        attachFiles: true,
        signersConfig: [
            {
                data: 'ARCX9012226P8'
            }
        ],
        watchers: [
            'correo@dominio.com'
        ]
    }   
}
`}
            </Code>
        default:
            return <Code lang="javascript">
                {`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        authority: 'SAT',
        stickerType: 'line',
        data: 'ARCX9012226P8',
        imageType: 'hash',
        email: 'jhon@gmail.com',
        page: 0,
        rect: {
            lx: 388,
            ly: 400,
            tx: 496,
            ty: 480
            } 
    }, {
        authority: 'EMAIL_LINK',
        stickerType: 'line',
        data: 'smith@gmail.com',
        imageType: 'hash',
        page: 1,
        rect: {
            lx: 388,
            ly: 400,
            tx: 496,
            ty: 480
        } 
    }],
    workflow: {
        subject: 'Asunto del correo de invitación',
        message: 'Mensaje personalizado en el correo de invitación',
        expirationDate: '12/11/2021',
        remindEvery: '1d',
        language: 'es',
        attachFiles: true,
        ordered: true,
        signersConfig: [
            {
                data: 'ARCX9012226P8'
            },
            {
                data: 'smith@gmail.com',
                notifications: 'only_start'
            } 
        ],
        watchers: [
            'bob@example.com'
        ]
    }   
})
`}
            </Code>
    }
}

export function html() {
    return (
        <React.Fragment>
            <Header>Flujos de firma</Header>
            <div className="Content">
                <p>
                    Un flujo de firmas toma un documento con stickers, lo manda a todos los
                    firmantes y te notifica cuando todos lo han firmado.
                </p>
                <p>
                    Para poder utilizar el flujo de firmas deberás especificar el elemento <code>email </code>
                    cuando envíes el documento a Firmamex (este es obligatorio cuando se pretende utilizar
                    el flujo de firmas y la autoridad es SAT). También se deberá especificar el
                    elemento <code>workflow </code> al momento
                    de dar de alta el documento.
                </p>
                <p>
                    Para poder utilizar el flujo de firmas, el documento deberá
                    contener por lo menos un Sticker.
                </p>
                <p>
                    El elemento <code>workflow </code> es un objeto que contiene los siguientes elementos:
                </p>
                <ul>
                    <li>
                        <strong>expirationDate:</strong> Con el se puede especificar la fecha de expiración
                        del flujo de firma.
                    </li>
                    <li><strong>remindEvery:</strong> Define que tan seguido se debe recordar a los firmantes.</li>
                    <li><strong>language:</strong> Lenguaje con el que se notificará a los firmantes.</li>
                    <li><strong>ordered:</strong> Indica si la firmas se deben solicitar de manera ordenada</li>
                    <li><strong>signersConfig: </strong> Permite indicar el orden de firma, o agregar configuración
                        adicional a cada firmante
                    </li>
                    <li><strong>watchers:</strong> Destinatarios no firmantes</li>
                    <li><strong>attachFiles:</strong> Adjuntar documentos</li>
                    <li><strong>subject:</strong> Asunto</li>
                    <li><strong>message:</strong> Mensaje personalizado</li>
                    <li><strong>alertAll:</strong> Notificar a todos</li>
                    <li><strong>blockExpired:</strong> Bloquear al expirar</li>
                    <li><strong>attachTimestamp:</strong> Enviar estampilla de tiempo en correo final</li>
                </ul>
                <br/>

                <p>
                    <strong>expirationDate</strong> (opcional, valor predeterminado: 2 semanas)
                </p>
                <p>
                    Especifica la fecha <strong>en UTC</strong> de expiración del flujo de firma.
                    El flujo será cancelado si no se ha completado antes de esta fecha.
                </p>
                <p>
                    Es posible especificar hora y minuto de expiración, si no se especifican, se toman 12:00 como
                    predeterminado.
                </p>
                <p>
                    Formato de fecha: dd/mm/yyyy [HH:mm]
                </p>
                <p> Por ejemplo: <br/>
                    12/11/2021 <br/>
                    12/11/2021 18:30
                </p>
                <br/>

                <p>
                    <strong>remindEvery</strong> (opcional, valor predeterminado: 1d)
                </p>
                <p>
                    Define que tan seguido se debe recordar a los firmantes que firmen
                    el documento.
                </p>
                <p>
                    Los valores permitidos son: none, 12h, 1d, 2d, 3d, 4d, 5d, 6d, 7d.
                </p>
                <p>
                    El valor de <strong>none</strong> es un caso especial, si se especifica este valor
                    el flujo enviará el correo de inicio y de fin, pero no enviará recordatorios
                </p>
                <br/>

                <p>
                    <strong>language</strong> (opcional, valor predeterminado: es)
                </p>
                <p>
                    Especifica el idioma en el que los firmantes recibirán la invitación a firmar
                    el documento en sus correos.
                </p>
                <p>
                    Los valores permitidos son: 'es' (Español) y 'en' (Inglés).
                </p>
                <br/>

                <p>
                    <strong>ordered</strong> (opcional, valor predeterminado: false)
                </p>
                <p>Define si las firmas serán solicitadas en orden.</p>
                <p>Los valores permitidos son: true, false</p>
                <p>
                    <strong>signersConfig</strong> (opcional)
                </p>
                <p>Permite especificar opciones adicionales para los firmantes</p>
                <p>Si se encuentra presente, debe ser un arreglo que contenga un elemento por cada firmante (no por cada sticker).</p>
                <p>Cuando ordered: true está configurado en el flujo, el orden de los firmantes en el arreglo determinará el orden requerido de firma.</p>
                <p>
                    Cada elemento del arreglo debe tener el siguiente formato
                    <ul>
                        <li><strong>data</strong>: Dato a verificar del firmante. Por ejemplo, si el firmante es por SAT, recibirá su
                            RFC
                        </li>
                        <li>
                            <strong>notifications</strong> (opcional, valor predeterminado "all"): Permite configurar las notificaciones que se enviarán a cada firmante. Los
                            valores permitidos son:
                            <ul>
                                <li><strong>all</strong>: Todas las notificaciones</li>
                                <li><strong>only_start</strong>: Recibe únicamente la primera notificación. En flujos no ordenados será al inicio del flujo; en flujos ordenados, será cuando sea su turno de firmar.</li>
                                <li><strong>only_end</strong>: Recibe sólo la notificación de flujo completado</li>
                                <li><strong>start_and_end</strong>: Recibe la notificación de inicio y la notificación de flujo
                                    completado. Pero no recibe los recordatorios
                                </li>
                                <li><strong>none</strong>: No recibe ninguna notificación
                                </li>
                            </ul>
                        </li>
                    </ul>
                </p>

                <br/>
                <p>
                    <strong>watchers</strong> (opcional)
                </p>
                <p>
                    Este campo es opcional, si se encuentra presente, estos correos recibiran una notificación cuando
                    inicie y otra
                    cuando termine el flujo, sin tener que ser parte de los firmantes
                </p>
                <br/>
                <p>
                    <strong>attachFiles</strong> (opcional, valor predeterminado: true)
                </p>
                <p>
                    Indica si se deben adjuntar los documentos firmados en el correo de notificación final.
                </p>
                <br/>
                <p>
                    <strong>subject</strong> (opcional, valor predeterminado: "Ha sido invitado a firmar un documento")
                </p>
                <p>
                    Permite personalizar el asunto de la invitación a firmar que se manda por correo.
                </p>
                <br/>
                <p>
                    <strong>message</strong> (opcional)
                </p>
                <p>
                    Permite inlcuir un mensaje personalizado dentro del correo de invitación
                </p>
                <br/>
                <p>
                    <strong>alertAll</strong> (opcional, valor predeterminado: true)
                </p>
                <p>
                    Si su valor es true, se notifica a todos los firmantes cuando se completa un flujo.
                    Si su valor es false, sólo se notifica al gestor del documento
                </p>
                <br/>
                <p>
                    <strong>blockExpired</strong> (opcional, valor predeterminado: false)
                </p>
                <p>
                    Si esta opción es true, el documento se bloqueará y no permitirá firmarse al expirar el flujo.
                </p>
                <br/>
                <p>
                    <strong>attachTimestamp</strong> (opcional, valor predeterminado: false)
                </p>
                <p>
                    Si esta opción es true, el correo de notificación de completado incluirá un zip con el sello de
                    tiempo NOM151 que se incrustó en el documento
                </p>
            </div>
        </React.Fragment>
    )
}

export default {JS, html}
