import React from 'react';
import Subtitle from '../../shared/Subtitle'
import BlueSection from '../../shared/BlueSection'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
        {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

{
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        "authority": "SAT",
        "stickerType": "line",
        "data": "ARCX9012226P8",
        "imageType":"hash",
        "email": 'jhon@gmail.com',
        "page": 0,
        "rect": {
            "lx": 388,
            "ly": 400,
            "tx": 388,
            "ty": 388
        }
    }]
}
        `}
        </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        authority: 'SAT',
        stickerType: 'line',
        data: 'ARCX9012226P8',
        imageType: 'hash',
        email: 'jhon@gmail.com',
        page: 0,
        rect: {
            lx: 388,
            ly: 400,
            tx: 496,
            ty: 480
            } 
    }] 
})
`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
`
{
    "document_ticket":"c6279a7c-b3d5-4c71-b417-4687a4f38914",
    "document_flow":false,
    "sticker_count":1,
    "stickers":[
        {
            "sticker_id":"qv3ZzAL3w",
            "sticker_index":0,
            "sticker_email":"firmante@dominiodelfirmante.com",
            "sticker_data":"firmante@dominiodelfirmante.com",
            "sticker_page":0,
            "sticker_coordinates":{
                "lx":388.0,
                "ly":400.0,
                "ux":496.0,
                "uy":480.0
            },
            "sticker_type":"EMAIL",
            "sticker_image_type":"hash",
            "sticker_authorities":[
                {
                "authority_name":"Vinculada a Correo Electrónico"
                }
            ]
        }
    ],
    "document_url":"https://firmamex.com/pdf/c6279a7c-b3d5-4c71-b417-4687a4f38914/?child=true&inv=true"
}
`
        }
    </Code>
}

export function html() {
    return(
        <React.Fragment>
            <Subtitle>A través del API</Subtitle>
            <div className="Content">
            <p>
                Puedes agregar stickers al documento agregandolos como un arreglo al método <code>request</code> del SDK
                que se utiliza para dar de alta al documento.
            </p>
            <p>
                <code>stickers</code> es un arreglo de objetos, cada objeto es un sticker y contiene los siguientes elementos:
            </p>
            <ul>
                <li><strong>authority:</strong> Es el emisor del certificado que puede firmar este Sticker.</li>
                <li><strong>stickerType:</strong> Se refiere al tipo de Sticker.</li>
                <li><strong>data:</strong> El dato a utilizar para validar al firmante.</li>
                <li><strong>imageType:</strong> Con este elemento se puede especificar el tipo de imagen que el firmante usará para firmar el documento.</li>
                <li><strong>email:</strong> El correo del firmante. (Si quieres que Firmamex maneje el flujo de firma este campo es obligatorio, si no, es opcional).</li>
                <li><strong>page:</strong> Página donde se debe de encontrar la firma. (Comienza desde 0).</li>
                <li><strong>rect:</strong> Posición de la firma dentro de la página en coordenadas PDF</li>
                <li><strong>requireIdentityVerification:</strong> Solicitar verificación remota de identidad al intentar firmar</li>
            </ul>
            <br />
            <p>
                <strong>authority</strong>
            </p>
            <p>
                La autoridad puede ser una de las siguientes:
            </p>
                <ul>
                    <li><strong>SAT:</strong> Para certificados del SAT. El firmante tendrá que identificarse con sus
                        archivos .cer y .key del SAT.
                    </li>
                    <li><strong>OAUTH_TOKEN:</strong> El firmante se verificará por medio de SSO. Usando
                    su cuenta Google, Outlook, o realizando verificación por medio de una Liga enviada al intentar firmar
                    </li>
                    <li><strong>WHATSAPP_LINK:</strong> El firmante recibirá una liga por WhatsApp para
                        poder
                        firmar el documento. La liga actúa como el mecanismo de verificación
                    </li>
                    <li><strong>SMS_LINK:</strong> El firmante recibirá una liga por SMS para poder
                        firmar el documento. La liga actúa como el mecanismo de verificación
                    </li>
                    <li><strong>EMAIL_LINK:</strong> El firmante recibirá una liga en su
                        correo electrónico para poder firmar el documento. La liga actúa como el mecanismo de
                        verificación
                    </li>
                    <li><strong>EMAIL:</strong> El firmante tendrá que responder a un correo
                        electrónico para verificarse.
                    </li>
                    <li><strong>SMS:</strong> El firmante recibirá la invitación a firmar por medio de un
                        SMS a su móvil.
                    </li>
                </ul>
                <BlueSection>
                    <p>
                        Con las autoridades por Liga sólo el firmante tiene la liga que autoriza firmar el documento a
                        su nombre, y la invitación la envía Firmamex en automático.
                    </p>
                    <p>
                Si se utilizan las opciones: SAT, EMAIL o SMS, si no se especifica un tipo de <a href="/docs/documento/flujos">Flujo </a>
                para estas opciones, es responsabilidad de la persona enviando el documento a firma (desarrollador) enviar el URL a los firmantes.
                <a href="/docs/documento/flujos"> Conocer más acerca de los Flujos</a>.
            </p>
            </BlueSection>
            <br />

            <p>
                <strong>stickerType</strong>
            </p>
            <p>
                Este es el tipo de Sticker que se va a usar, existen dos opciones:
            </p>
            <ul>
                <li><strong>rect:</strong> Centra la firma en el área proporcionada</li>
                <li><strong>line:</strong> Coloca la firma al centro y en la parte inferior del área proporcionada</li>
            </ul>
            <br />

            <p>
                <strong>data</strong>
            </p>
            <p>
                Este es el dato en sí que se utilizará para validar al firmante.
            </p>
            <p>
                Ejemplos:
            </p>
            <ul>
                <li>firmante@tudominio.com</li>
                <li>662 900 00 00</li>
                <li>ARCX9012226P8</li>
            </ul>
            <br />

            <p>
                <strong>imageType</strong>
            </p>
            <p>
                Este campo es opcional, y se refiere al tipo de imagen que el firmante usara para firmar el Sticker.
            </p>
            <p>
                Las opciones disponibles son:
            </p>
            <ul>
                <li><strong>desc:</strong> Imagen con el texto "Firma realizada con certificado emitido por [emisor] para: [nombre]".</li>
                <li><strong>name:</strong> Nombre del firmante tal como se encuentra en su certificado, escrito con una fuente de escritura a mano. </li>
                <li><strong>hash:</strong> El base64 del sha256 del documento antes de ser firmado seguido por el nombre del firmante. </li>
                <li><strong>stroke:</strong> La firma dibujada dentro de la app.</li>
            </ul>
            <br />

            <p>
                <strong>email</strong>
            </p>
            <p>
                Este campo es opcional. Si quieres que Firmamex maneje el <a href="/docs/documento/flujos">Flujo</a> de firmado este campo
                deberá ser proporcionado.
            </p>
            <p>
                Recibe un string con el email del firmante.
            </p>
            <br />

            <p>
                <strong>page</strong>
            </p>
            <p>
                Este elemento recibe un número haciendo referencia a la página donde se debe encontrar la firma. El conteo de las páginas empieza desde 0.
            </p>
            <br />

            <p>
                <strong>rect</strong>
            </p>
            <p>Con este elemento se designan las coordenadas para la posición del Sticker dentro de la página.
                El (0,0) es la esquina inferior izquierda.
            </p>
            <ul>
                <li><strong>lx:</strong> x inferior izquierda</li>
                <li><strong>ly:</strong> y inferior izquierda</li>
                <li><strong>tx:</strong> x superior derecha</li>
                <li><strong>ty:</strong> y superior derecha</li>
            </ul>
            <br />
            </div>
        </React.Fragment>
    )
}
export default {html, JS, Response}
